import SharedLink from '@/components/shared/buttons/shared-link'
import { useNavigate } from 'react-router-dom'

type Props = {
  className?: string
  label?: string
}

const BackButton = ({ className, label }: Props): JSX.Element => {
  const hasPreviousPage = () => window.history.state !== null && window.history.length > 1
  const navigate = useNavigate()

  const handleBrowserBack = (e: MouseEvent) => {
    e.preventDefault()
    window.history.back()
  }

  // Use navigate(-1) to go back one step in history, same as window.history.back() but React Router way
  const handlerRouterBack = (e: MouseEvent) => {
    e.preventDefault()
    navigate(-1)
  }

  return hasPreviousPage() ? (
    <div className={`inline-flex items-center gap-2 ${className}`}>
      <SharedLink className="p-[0px]" href="#" onClick={handlerRouterBack} style="checkout">
        &larr; {label}
      </SharedLink>
    </div>
  ) : (
    <div></div>
  )
}

export default BackButton
