import { epiGetProduct, epiGetVariantByContentGuid } from '@/api/content-delivery'
import { ProductContentModel, VariantContentModel } from '@/api/types/content-delivery-types'
import { ValidationIssue } from '@/api/validation'
import { addToCartGTM, removeFromCartGTM, selectItemOnCartGTM } from '@/components/shared/analytics/gtm-manager'
import SharedButton from '@/components/shared/buttons/shared-button'
import SharedLink from '@/components/shared/buttons/shared-link'
import Price from '@/components/shared/price/price'
import { currencyToLocale } from '@/components/shared/price/types/price-types'
import { DEFAULT_CURRENCY_CODE } from '@/lib/constants'
import { formatDateShort } from '@/lib/currency/formatting'
import { useCartStore } from '@/stores/useCartStore'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import { toast } from 'react-toastify'
import { uid } from 'uid'
import { useDebounceValue, useIntersectionObserver } from 'usehooks-ts'
import { ProductModel } from '../../product-detail/types/product-detail-types'
import { CartLineItemProps } from '../types/cart-page-types'
import { TrashIcon } from '@heroicons/react/24/solid'


const CartLineItem = (props: CartLineItemProps) => {
  const {t} = useTranslation()
  
  const { loadUserCart } = useCartStore()
  const [product, setProduct] = useState<ProductModel>()
  const [isBlockVisible, setIsBlockVisible] = useState(false)
  const [lineTotal, setLineTotal] = useState<number>(0)
  const ref = useRef<HTMLDivElement | null>(null)
  const entry = useIntersectionObserver(ref, { threshold: 0.1 })
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const isVisible = !!entry?.isIntersecting
  const [contentData, setContentData] = useState<VariantContentModel | null>(null)

  const {
    localizations,
    cartLineModel,
    cartInfo,
    pdpUrl,
    validationIssues,
    removeCartItem,
    updateCartLineQuantity,
    calculateCartLine,
    eventRegistrationAttendance,
  } = props
  
  const isEvent = contentData?.contentType.includes("WebEvent") ?? true
  
  const { displayName, placedPrice, contentId, quantity, code, id } = cartLineModel

  if (id == undefined) {
    throw new Error('CartLineItem Component: cart line id shouldn\'t be undefined')
  }

  const [inputQuantity, setInputQuantity] = useState<number>(quantity)
  const [debouncedInputValue] = useDebounceValue(inputQuantity, 300)

  const unavailable =
    validationIssues?.includes(ValidationIssue.RemovedDueToInvalidMaxQuantitySetting) ||
    validationIssues?.includes(ValidationIssue.RemovedDueToInsufficientQuantityInInventory) ||
    validationIssues?.includes(ValidationIssue.RemovedDueToUnavailableItem)

  const issueDisplay =
    validationIssues?.includes(ValidationIssue.RemovedDueToInvalidMaxQuantitySetting) ||
    validationIssues?.includes(ValidationIssue.RemovedDueToInsufficientQuantityInInventory) ||
    validationIssues?.includes(ValidationIssue.RemovedDueToUnavailableItem) ||
    validationIssues?.includes(ValidationIssue.AdjustedQuantityByAvailableQuantity) ||
    validationIssues?.includes(ValidationIssue.AdjustedQuantityByMaxQuantity) ||
    validationIssues?.includes(ValidationIssue.AdjustedQuantityByMinQuantity)

  const adjustedQuantityByAvailableQuantity = validationIssues?.includes(
    ValidationIssue.AdjustedQuantityByAvailableQuantity,
  )
  const adjustedQuantityByMaxQuantity = validationIssues?.includes(ValidationIssue.AdjustedQuantityByMaxQuantity)
  const adjustedQuantityByMinQuantity = validationIssues?.includes(ValidationIssue.AdjustedQuantityByMinQuantity)

  const onQuantityChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = +event.target.value
    const max = contentData?.maxQuantity ?? 1
    const min = contentData?.minQuantity ?? 1
    if (targetValue > max) targetValue = max
    if (targetValue < min) targetValue = min
    setInputQuantity(targetValue)
  }

  useEffect(() => {
    setInputQuantity(quantity)
  }, [quantity])

  useEffect(() => {
    const getVariantData = async () => {
      const content = await epiGetVariantByContentGuid(contentId)
      if (content) {
        setContentData(content)
      }
    }
    if (isVisible && !imageLoaded) getVariantData()
  }, [isVisible])

  useEffect(() => {
    const setTotal = async () => {
      const lineTotal = await calculateCartLine(id, debouncedInputValue)
      if (lineTotal) {
        setLineTotal(lineTotal)
      }
    }
    setTotal()
  }, [debouncedInputValue, placedPrice])

  useEffect(() => {
    if (debouncedInputValue !== quantity) {
      const quantityDelta = debouncedInputValue - quantity
      if (quantityDelta > 0) {
        // Push the 'add_to_cart' GTM event
        addToCartGTM(productId, contentData, placedPrice, quantityDelta, cartInfo)
      } else {
        // Push the 'remove_from_cart' GTM event
        removeFromCartGTM(productId, contentData, placedPrice, quantityDelta, cartInfo)
      }
      updateCartLineQuantity(id, debouncedInputValue)
    }
  }, [debouncedInputValue])


  const toggleBlockVisibility = () => {
    setIsBlockVisible(!isBlockVisible)
  }

  const productId = contentData?.parentLink?.id

  const pushSelectItemGTM = () => {
    if (!productId) return
    selectItemOnCartGTM(cartLineModel, productId)
  }

  const handleRemoveCartItem = async () => {
    await removeCartItem(id, code, contentData?.linkedSku ?? "")
    // Push the 'remove_from_cart' GTM event
    removeFromCartGTM(productId, contentData, placedPrice, quantity, cartInfo)
    await loadUserCart()
    if(contentData?.linkedSku)
      {
        toast(
          <div className="flex flex-col gap-2">
            <span className="text-red-600">{t("Cart.RemovedEventTitle")}</span>
            <span className="text-black">{t("Cart.RemovedEventDescription")}</span>
          </div>,
          { position: 'top-right' },
        )
      }
  }

  useEffect(() => {
    if (!productId) return
    const getProduct = async () => {
      const productResponse = await epiGetProduct(productId.toString())
      setProduct(productContentModelToProductModel(productResponse))
    }
    getProduct()
  }, [productId])

  return (
    <div
      className={`py-1 ${issueDisplay ? 'shadow-sm shadow-red-600' : ''}`}
      ref={ref}
      style={{
        width: '100%',
        minHeight: 'min-content',
        fontSize: '2rem',
      }}
    >
      <div className="flex flex-row gap-3">
        <SharedLink
          className={`${isEvent ? "pointer-events-none" : ""}`}
          href={isEvent ? undefined : `${pdpUrl}?id=${contentData?.parentLink?.id}` }
          title={displayName}
          onClick={pushSelectItemGTM}
        >
          <div className="w-[125px]">
            <LazyLoad once height={125} style={{ filter: imageLoaded ? 'none' : 'blur(10px)' }}>
              <img
                alt={displayName}
                src={contentData?.assets[0]}
                className="h-[125px] w-[125px] rounded-lg object-cover"
                onLoad={() => setImageLoaded(true)}
              />
            </LazyLoad>
          </div>
        </SharedLink>
        <div className="flex w-full flex-col">
          <div className="flex w-full flex-col justify-between gap-3 lg:flex-row">
            <div className="flex flex-col">
              <div className="pb-3">
                <div className="flex">
                  <SharedLink
                    className={`text-xl font-semibold text-grey-dark hover:text-grey-dark ${isEvent ? "pointer-events-none" : ""}`}
                    href={isEvent ? undefined : `${pdpUrl}?id=${contentData?.parentLink?.id}`}
                    title={displayName}
                    onClick={pushSelectItemGTM}
                  >
                    {displayName}
                  </SharedLink>
                </div>
                {product?.productType !== '' && (
                  <div className="flex items-center">
                    <span className="text-base font-semibold text-grey-dark">{product?.productType}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-2 pb-3 pr-3">
              <div className="text-xl font-bold text-grey-dark lg:text-right">
                <Price
                  className={issueDisplay ? 'text-red-600' : ''}
                  currency={cartInfo?.currency}
                  price={lineTotal.toString()}
                  culture={currencyToLocale[cartInfo?.currency ?? DEFAULT_CURRENCY_CODE]}
                />
              </div>
              <div className="flex flex-row gap-2 text-base font-semibold text-grey-dark lg:text-right">
                <span className="whitespace-nowrap">Unit Price: </span>
                <Price
                  currency={cartInfo?.currency}
                  price={placedPrice.toString()}
                  culture={currencyToLocale[cartInfo?.currency ?? DEFAULT_CURRENCY_CODE]}
                  className="whitespace-nowrap"
                />
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col justify-between gap-3 lg:flex-row">
            <div className="flex flex-col">
              <div className="flex flex-row items-end">
                {!eventRegistrationAttendance && (
                  <div className="mr-4 flex flex-col">
                    <div className="pb-1 text-sm font-bold text-grey-dark">Qty</div>
                    <div className="flex w-[80px] items-center justify-center space-x-2 rounded-lg border border-grey-100 py-1 text-base shadow-sm">
                      <input
                        type="number"
                        className="w-full min-w-fit text-center text-base text-dark-blue-primary"
                        min={contentData?.minQuantity ?? 1}
                        max={contentData?.maxQuantity ?? 1}
                        step="1"
                        value={inputQuantity}
                        onChange={async (event) => await onQuantityChange(event)}
                        onKeyDown={(event) => {
                          // Allow arrow keys, backspace, delete, and tab
                          if (
                            !['ArrowUp', 'ArrowDown', 'Backspace', 'Delete', 'Tab'].includes(event.key) &&
                            !/^\d*$/u.test(event.key)
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="flex items-center gap-2 cursor-pointer group" onClick={handleRemoveCartItem}>
                  <TrashIcon className="cursor-pointer fill-dark-blue-primary w-6 h-6" onClick={handleRemoveCartItem} />
                  <SharedButton
                    style="checkout"
                    className="cursor-pointer !px-0 text-base font-bold text-dark-blue-primary uppercase group-hover:underline"
                    onClick={handleRemoveCartItem}
                  >
                    Remove
                  </SharedButton>
                </div>
              </div>
            </div>
          </div>
          {eventRegistrationAttendance && (
            <div className="mt-3 flex max-w-[500px] flex-col gap-2">
              <div className="max-w-[400px] text-base">
                <div className="border-b border-grey-300">
                  <span>{localizations.dateLabel ?? 'Date:'} </span>
                  {formatDateShort(eventRegistrationAttendance.date?.toString() ?? '')}
                </div>
                <div className="border-b border-grey-300">
                  <span>{localizations.locationLabel ?? 'Location: '}{eventRegistrationAttendance.isVirtual ? "Virtual" : ""}</span>                  
                  {!eventRegistrationAttendance.isVirtual && eventRegistrationAttendance?.location?.split('\n').map((line, index) => (
                    <p key={uid()}>{line}</p>
                  ))}
                </div>
              </div>
              <div className="my-3">
                <SharedButton
                  style='checkout'
                  className="flex items-center gap-1 text-left text-base uppercase text-dark-blue-primary"
                  onClick={toggleBlockVisibility}
                >
                  {isBlockVisible ? (
                    <ChevronDownIcon className="h-5 w-5 text-dark-blue-primary" />
                  ) : (
                    <ChevronRightIcon className="h-5 w-5 text-dark-blue-primary" />
                  )}
                  {localizations.attendeesLabel ?? 'Attendees'}
                </SharedButton>

                {isBlockVisible && (
                  <ul className="mt-3 flex flex-col gap-4 text-sm">
                    {eventRegistrationAttendance?.attendees?.map((value, index) => (
                      <li className="border-b border-grey-300" key="index">
                        {index + 1}. {value.title}. {value.firstname} {value.lastname} - {value.email}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full px-3 text-right">
        {adjustedQuantityByMaxQuantity && (
          <span className="text-sm text-red-600">
            {t('CartLine.Errors.AdjustedQuantityByMaxQuantity')}
          </span>
        )}
        {adjustedQuantityByMinQuantity && (
          <span className="text-sm text-red-600">
            {t('CartLine.Errors.AdjustedQuantityByMinQuantity')}
          </span>
        )}
        {adjustedQuantityByAvailableQuantity && (
          <span className="text-sm text-red-600">
            {t('CartLine.Errors.AdjustedQuantityByAvailableQuantity')}
          </span>
        )}
        {unavailable && (
          <span className="text-sm text-red-600">
            {localizations.productUnavailable ?? t('CartLine.Errors.Unavailable')}
          </span>
        )}
      </div>
    </div>
  )
}
export default CartLineItem

function productContentModelToProductModel(productContentModel: ProductContentModel) {
  return {
    productType: productContentModel.productType,
  } as ProductModel
}
