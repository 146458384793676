import AccordionBlock from '@/components/blocks/accordion/accordion-block'
import CareersBlock from '@/components/blocks/careers/careers-block'
import CtaDisruptorBlock from '@/components/blocks/cta-disruptor/cta-disruptor'
import CtaWithInputBlock from '@/components/blocks/cta-with-input/cta-with-input'
import ErrorBlock from '@/components/blocks/error-block'
import FeaturedMediaBlock from '@/components/blocks/featured-media-block/featured-media-block'
import FeaturedTextToImageBlock from '@/components/blocks/featured-text-to-image/featured-text-to-image'
import FinalScoreSectionBlock from '@/components/blocks/workplace-training-results/final-score-section/final-score-section-block'
import FormContainerBlock from '@/components/blocks/form-container/form-container'
import FullWidthCalloutBlock from '@/components/blocks/full-width-callout/full-width-callout'
import Image3To1HeroBlock from '@/components/blocks/hero/image-3to1/image-3to1-hero-block'
import Image16To9HeroBlock from '@/components/blocks/hero/image-16to9/image-16to9-hero-block'
import ResourceDownloadPageHeroBlock from '@/components/blocks/hero/resource-download-page-hero-block/resource-download-page-hero-block'
import IndexScoreBreakdownBlock from '@/components/blocks/workplace-training-results/index-score-breakdown/index-score-breakdown-block'
import NoImageHeroBlock from '@/components/blocks/hero/no-image/no-image-hero-block'
import IconBlock from '@/components/blocks/icon/icon-block'
import {
  default as ContentRecommendationsBlock,
  default as ImageCardBlock,
} from '@/components/blocks/image-card/image-card-block'
import ImageGalleryBlock from '@/components/blocks/image-gallery/image-gallery'
import ImageBlock from '@/components/blocks/image/image-block'
import IndustriesBlock from '@/components/blocks/industries/industries'
import LayoutBlock from '@/components/blocks/layout/layout-block'
import LeadGenerationFormWrapperBlock from '@/components/blocks/lead-gen-form/lead-gen-form'
import { LongformResourcesBlock } from '@/components/blocks/longform-resources/longform-resources-block'
import MultiColumnTextBlock from '@/components/blocks/multi-column-text/multi-column-text-block'
import MultiColumnBlock from '@/components/blocks/multi-column/multi-column-block'
import PromoBannerBlock from '@/components/blocks/promo-banner/promo-banner'
import QuoteBlock from '@/components/blocks/quote/quote-block'
import RecentBlogsBlock from '@/components/blocks/recent-blogs/recent-blogs-block'
import RichTextBlock from '@/components/blocks/rich-text/rich-text-block'
import CommerceRichTextBlock from '@/components/blocks/commerce-rich-text/commerce-rich-text-block'
import ScheduleConsultationBlock from '@/components/blocks/workplace-training-results/schedule-a-consultation/schedule-a-consultation-block'
import ScoreRangeBlock from '@/components/blocks/workplace-training-results/score-range/score-range-block'
import ScoreResultBlock from '@/components/blocks/workplace-training-results/score-result/score-result-block'
import ScrimCTAForm from '@/components/blocks/scrim-cta-form/scrim-cta-form'
import { SimpleLinkSliderBlock } from '@/components/blocks/simple-link-slider/simple-link-slider-block'
import SiteAlertBlock from '@/components/blocks/site-alert/site-alert'
import StatisticsLinkBlock from '@/components/blocks/statistics-link-card-block/statistics-link-card-block'
import StatisticsBlock from '@/components/blocks/statistics/statistics-block'
import TableBlock from '@/components/blocks/table/table-block'
import TrainingApproachBlock from '@/components/blocks/training-approach/trainging-approach'
import VideoBlock from '@/components/blocks/video-block/video-block'
import TrackingScriptBlock from '../components/blocks/tracking-script/tracking-script-block'
import { BlockComponentSelectorProps, BlockModelProps } from './types/epi'

const getBlockComponent = (model: any, components: any) => {
  if (model?.contentType && components) {
    // Find a component that matches the content type name.
    for (let i = model.contentType.length - 1; i >= 0; i--) {
      const type = model.contentType[i]
      const component = components[type]

      if (component) {
        return component
      }
    }
  }

  return ErrorBlock
}

const handleDesktopDisplayWidth = (model: BlockModelProps) => {
  if (!model?.contentType) {
    return
  }

  if (
    model.contentType.includes('CommerceRichTextBlock') ||
    model.contentType.includes('RichTextBlock') ||
    model.contentType.includes('TableBlock')
  ) {
    if (!model.displayWidths) {
      return
    }

    switch (model.displayWidths.desktopWidth) {
      case 'full':
        return 'xl:col-span-12'
      case '3/4':
        return 'xl:col-span-9'
      case '2/3':
        return 'xl:col-span-8'
      case '1/2':
        return 'xl:col-span-6'
      case '1/3':
        return 'xl:col-span-4'
      case '1/4':
        return 'xl:col-span-3'
    }
  }
}

const handleTabletDisplayWidth = (model: BlockModelProps) => {
  if (!model?.contentType) {
    return
  }

  if (
    model.contentType.includes('CommerceRichTextBlock') ||
    model.contentType.includes('RichTextBlock') ||
    model.contentType.includes('TableBlock')
  ) {
    if (!model.displayWidths) {
      return
    }

    switch (model.displayWidths.tabletWidth) {
      case 'full':
        return 'md:col-span-12'
      case '2/3':
        return 'md:col-span-8'
      case '1/2':
        return 'md:col-span-6'
      case '1/3':
        return 'md:col-span-4'
    }
  }
}

const handleMobileDisplayWidth = (model: Readonly<BlockModelProps>) => {
  if (!model?.contentType) {
    return
  }

  if (
    model.contentType.includes('CommerceRichTextBlock') ||
    model.contentType.includes('RichTextBlock') ||
    model.contentType.includes('TableBlock')
  ) {
    return 'col-span-12'
  }
}

const BlockComponentSelector = (
  props: Readonly<BlockComponentSelectorProps>,
) => {
  const {
    model,
    hideContainerClass = false,
    isModal,
    isChildBlock,
    isSidebar,
  } = props
  const components = {
    AccordionBlock,
    CtaWithInputBlock,
    ErrorBlock,
    FinalScoreSectionBlock,
    FormContainerBlock,
    IconBlock,
    Image3To1HeroBlock,
    Image16To9HeroBlock,
    ResourceDownloadPageHeroBlock,
    ImageCardBlock,
    IndexScoreBreakdownBlock,
    ContentRecommendationsBlock,
    ImageGalleryBlock,
    IndustriesBlock,
    LayoutBlock,
    LongformResourcesBlock,
    NoImageHeroBlock,
    RecentBlogsBlock,
    CommerceRichTextBlock,
    RichTextBlock,
    SimpleLinkSliderBlock,
    ScoreRangeBlock,
    ScoreResultBlock,
    StatisticsBlock,
    TableBlock,
    TrainingApproachBlock,
    CtaDisruptorBlock,
    FeaturedMediaBlock,
    QuoteBlock,
    CareersBlock,
    MultiColumnTextBlock,
    PromoBannerBlock,
    FullWidthCalloutBlock,
    LeadGenerationFormWrapperBlock,
    ScrimCTAForm,
    FeaturedTextToImageBlock,
    MultiColumnBlock,
    ImageBlock,
    VideoBlock,
    SiteAlertBlock,
    ScheduleConsultationBlock,
    StatisticsLinkBlock,
    TrackingScriptBlock,
  }
  const BlockComponent = getBlockComponent(model, components)
  const classNames = [
    handleMobileDisplayWidth(model),
    handleTabletDisplayWidth(model),
    handleDesktopDisplayWidth(model),
  ]
    .filter((i) => i !== undefined)
    .filter((i) => i !== '')
    .join(' ')

  if (
    model.contentType?.includes('RichTextBlock') ||
    model.contentType?.includes('TableBlock') ||
    !model.anchorId
  ) {
    return (
      <BlockComponent
        model={model}
        hideContainerClass={hideContainerClass}
        isModal={isModal}
        isSidebar={isSidebar}
        isChildBlock={isChildBlock}
        className={classNames}
      />
    )
  } else {
    return (
      <div id={model.anchorId}>
        <BlockComponent
          model={model}
          hideContainerClass={hideContainerClass}
          isModal={isModal}
          isSidebar={isSidebar}
          isChildBlock={isChildBlock}
          className={classNames}
        />
      </div>
    )
  }
}
export default BlockComponentSelector
