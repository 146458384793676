import BlockComponentSelector from '@/epi/block-component-selector'
import { useEffect, useState } from 'react'
import { ResourceDownloadResultsPageProps } from './types/resource-download-results-page-types'
import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedRtf from '@/components/shared/rtf/shared-rtf'
import SharedImage from '@/components/shared/image/shared-image'


const handleScript = (customScript: string) => {
    const script = document.createElement('script')
    script.type = 'application/javascript'
    script.text = customScript
    document.body.appendChild(script)
}

const ResourceDownloadResultsPage: React.FC<
    ResourceDownloadResultsPageProps
> = ({ model }) => {
    const [pageIsLoaded, setPageIsLoaded] = useState(false)

    useEffect(() => {
        setPageIsLoaded(true)
    }, [])

    useEffect(() => {
        if (pageIsLoaded) {
            // if (model?.formScripts) {
            //     handleScript(model.formScripts)
            // }
            // if (model?.additionalFormScripts) {
            //     handleScript(model.additionalFormScripts)
            // }
            console.log('page is loaded')
        }
    }, [pageIsLoaded])

    return (
        <>
            <Breadcrumbs />
            <div className="container flex flex-col space-y-12 md:space-y-16">
                <div className='flex flex-col lg:flex-row lg:justify-between items-center space-y-12'>
                        {model.resourceDownloadResultsPageBodyImage?.url && (
                            <div className="lg:flex lg:items-center mb-4 lg:mb-0 ">
                            <SharedImage className="w-[300px] lg:w-[400px]" {...model.resourceDownloadResultsPageBodyImage} disableLazy />
                            </div>
                    )}
                    {model.resourceDownloadResultsPageBodyText && (
                        <div className='w-full h-auto lg:w-2/5'>
                            <SharedRtf html={model.resourceDownloadResultsPageBodyText} />
                        </div>
                    )}
                </div>
                <div className="flex flex-col space-y-16 md:space-y-20">
                    {model.resourceDownloadResultsPageContent?.map((item, i) => (
                        <BlockComponentSelector {...item} key={`pageContent-${i}`} />
                    ))}
                </div>
            </div>
        </>
    )
}
export default ResourceDownloadResultsPage
