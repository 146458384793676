import BlockComponentSelector from '@/epi/block-component-selector'
import { useEffect, useState } from 'react'
import { ResourceDownloadFormPageProps } from './types/resource-download-form-page-types'
import Breadcrumbs from '@/components/shared/breadcrumbs/breadcrumbs'
import SharedRtf from '@/components/shared/rtf/shared-rtf'


const handleScript = (customScript: string) => {
    const script = document.createElement('script')
    script.type = 'application/javascript'
    script.text = customScript
    document.body.appendChild(script)
}

const ResourceDownloadFormPage: React.FC<
    ResourceDownloadFormPageProps
> = ({ model }) => {
    const [pageIsLoaded, setPageIsLoaded] = useState(false)

    useEffect(() => {
        setPageIsLoaded(true)
    }, [])

    useEffect(() => {
        if (pageIsLoaded) {
            if (model?.resourceDownloadFormScript) {
                handleScript(model.resourceDownloadFormScript)
            }
            if (model?.resourceDownloadAdditionalFormScript) {
                handleScript(model.resourceDownloadAdditionalFormScript)
            }
        }
    }, [pageIsLoaded])

    return (
        <>
            <Breadcrumbs />
            <div className="container flex flex-col space-y-12 md:space-y-16">
                {model.hero && <BlockComponentSelector {...model.hero} />}
                {(model.resourceDownloadFormPageBodyText ||
                    model.resourceDownloadFormScript ||
                    model.resourceDownloadAdditionalFormScript) && (
                        <div className="flex flex-col lg:flex-row items-center justify-center space-y-12">
                            {model.resourceDownloadFormPageBodyText && (
                                <div className='w-full h-auto lg:w-2/5'>
                                    <SharedRtf html={model.resourceDownloadFormPageBodyText} />
                                    {/* <div dangerouslySetInnerHTML={{ __html: model.resourceDownloadFormPageBodyText }} /> */}
                                </div>
                            )}
                            {model.resourceDownloadFormScript && (
                                <div className='h-auto lg:w-3/5'>
                                    <div dangerouslySetInnerHTML={{ __html: model.resourceDownloadFormScript }} />
                                </div>
                            )}
                        </div>
                    )}
                <div className="flex flex-col space-y-16 md:space-y-20">
                    {model.resourceDownloadPageContent?.map((item, i) => (
                        <BlockComponentSelector {...item} key={`pageContent-${i}`} />
                    ))}
                </div>
            </div>
        </>
    )
}
export default ResourceDownloadFormPage
