import BlogDetailPage from '@/components/pages/blog-detail/blog-detail'
import CaseStudyDetailPage from '@/components/pages/case-study-detail/case-study-detail'
import CaseStudyIndexPage from '@/components/pages/case-study/index-page'
import ContactUsPage from '@/components/pages/contact-us/contact-us'
import ErrorPage from '@/components/pages/error/error-page'
import FindTrainingPage from '@/components/pages/find-training/find-training'
import GeneralDetailPage from '@/components/pages/general-detail/general-detail-page'
import HomePage from '@/components/pages/home/home-page'
import {
  default as BlogIndexPage,
  default as LegislationIndexPage,
  default as LibraryIndexPage,
  default as VideoIndexPage,
} from '@/components/pages/index/index-page'
import LandingPage from '@/components/pages/landing/landing-page'
import LegislationDetailPage from '@/components/pages/legislation-detail/legislation-detail'
import LibraryDetailPage from '@/components/pages/library-detail/library-detail'
import LibraryGeneralDetailPage from '@/components/pages/library-general-detail/library-general-detail'
import OrderConfirmationPage from '@/components/pages/order-confirmation/order-confirmation-page'
import ProductListingPage from '@/components/pages/plp/product-listing-page'
import LocationInterstitial from '@/components/pages/program/components/location-interstitial'
import ProgramPage from '@/components/pages/program/program-page'
import ResourceDownloadFormPage from '@/components/pages/resource-download-form/resource-download-form-page'
import ResourceDownloadResultsPage from '@/components/pages/resource-download-results/resource-download-results-page'
import SearchPage from '@/components/pages/search/search-page'
import VideoDetailPage from '@/components/pages/video-detail/video-detail'
import WorkplaceTrainingLandingPage from '@/components/pages/workplace-training-landing/workplace-training-landing-page'
import WorkplaceTrainingResultsPage from '@/components/pages/workplace-training-results/workplace-training-results-page'
import { PageComponentSelectorProps } from '@/epi/types/epi'
import ProductDetailPage from '../components/pages/product-detail/product-detail-page'
import PageHead from './page-head'
import CartPage from '@/components/pages/cart/cart-page'
import CheckoutPage from '@/components/pages/checkout/checkout-page'
import EventRegistrationPage from '@/components/pages/event-registration/event-registration'
import OrderHistoryListingPage from '@/components/pages/order-history/order-history'
import OrderDetailsPage from '@/components/pages/order-details/order-details-page'
import AddressPage from '@/components/pages/address/address-page'

const getPageComponent = (model: any, components: any) => {
  if (model?.contentType && components) {
    // Find a component that matches the content type name.
    for (let i = model.contentType.length - 1; i >= 0; i--) {
      const currentContentType = model.contentType[i]
      const component = components[currentContentType]

      if (component) {
        return component
      }
    }
  } else {
    return ErrorPage
  }
}

const PageComponentSelector = (props: Readonly<PageComponentSelectorProps>) => {
  const { model } = props
  const components: any = {
    BlogDetailPage,
    BlogIndexPage,
    ErrorPage,
    FindTrainingPage,
    GeneralDetailPage,
    HomePage,
    LandingPage,
    LegislationDetailPage,
    LegislationIndexPage,
    LibraryDetailPage,
    LibraryGeneralDetailPage,
    LibraryIndexPage,
    OrderConfirmationPage,
    ProgramPage,
    ResourceDownloadFormPage,
    ResourceDownloadResultsPage,
    SearchPage,
    VideoDetailPage,
    VideoIndexPage,
    CaseStudyIndexPage,
    CaseStudyDetailPage,
    ContactUsPage,
    WorkplaceTrainingLandingPage,
    WorkplaceTrainingResultsPage,
    CartPage,
    CheckoutPage,
    ProductListingPage,
    ProductDetailPage,
    EventRegistrationPage,
    OrderHistoryListingPage,
    OrderDetailsPage,
    AddressPage
  }

  const PageComponent = getPageComponent(model, components)

  return (
    <>
      <PageHead {...model} />
      <PageComponent model={model} />
      <LocationInterstitial />
    </>
  )
}

export default PageComponentSelector
