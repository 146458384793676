import SharedImage from '@/components/shared/image/shared-image'
import { ResourceDownloadPageHeroBlockProps } from './types/resource-download-page-hero-block'

const ResourceDownloadPageHeroBlock: React.FC<ResourceDownloadPageHeroBlockProps> = ({
    model,
}) => {
    return (
        <div className="flex flex-col justify-center items-center space-y-4 lg:flex-row lg:content-between lg:justify-between lg:items-center rounded-lg">
            <div className={model.resourceDownloadPageHeroText ? "mt-4 md:w-1/2" : "mt-0"}>
                {model.resourceDownloadPageHeroText && (
                    <div
                        className="text-4xl text-center lg:text-left lg:text-6xl text-grey-dark font-bold"
                        dangerouslySetInnerHTML={{ __html: model.resourceDownloadPageHeroText }}
                    />
                )}
            </div>

            {model.resourceDownloadPageHeroImage?.url && (
                <div className="lg:flex lg:items-center mb-4 lg:mb-0 ">
                    <SharedImage className="w-[450px] lg:w-[600px]" {...model.resourceDownloadPageHeroImage} disableLazy />
                </div>
            )}
        </div>
    )
}

export default ResourceDownloadPageHeroBlock
