import { epiGetCatalogChildren, epiGetInventory, epiGetPrices, epiGetProduct } from '@/api/content-delivery'
import {
  InventoryModel,
  PriceContentModel,
  ProductContentModel,
  VariantContentModel,
} from '@/api/types/content-delivery-types'
import BackButton from '@/components/pages/product-detail/components/product-back-button'
import { addToCartGTM, viewItemGTM } from '@/components/shared/analytics/gtm-manager'
import SharedButton from '@/components/shared/buttons/shared-button'
import SharedImage from '@/components/shared/image/shared-image'
import Price from '@/components/shared/price/price'
import { currencyToLocale } from '@/components/shared/price/types/price-types'
import SharedRtf from '@/components/shared/rtf/shared-rtf'
import TierPricing from '@/components/shared/tier-pricing/tier-pricing'
import PageHead from '@/epi/page-head'
import { RenderContentArea } from '@/epi/pageUtils/render-content-area'
import { DEFAULT_CURRENCY_CODE, DEFAULT_MARKET } from '@/lib/constants'
import withHydration from '@/lib/withHydration'
import { useGlobalSettings } from '@/stores/global-settings'
import { useCartStore } from '@/stores/useCartStore'
import { useUserStore } from '@/stores/useUserStore'
import { InformationCircleIcon, ShoppingCartIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ProductDetailPageProps, ProductModel, VariantModel } from './types/product-detail-types'

const ProductDetailPage = (props: Readonly<ProductDetailPageProps>) => {
  const globalSettings = useGlobalSettings((state) => state.globalSettings)
  const { user } = useUserStore()
  const { model } = props
  const { localizations } = model
  const [product, setProduct] = useState<ProductModel>()
  const [variants, setVariants] = useState<VariantModel[]>([])
  const [currentVariant, setCurrentVariant] = useState<VariantModel>()
  const [currentInventories, setCurrentInventories] = useState<InventoryModel[]>()
  const [quantity, setQuantity] = useState<number>(1)
  const { cartData, addToCart, loadUserCart } = useCartStore()
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const productId = searchParams.get('id')
  const culture = currencyToLocale[user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE]

  useEffect(() => {
    loadUserCart()
  }, [])

  const navigateToCart = () => {
    navigate(globalSettings?.cartPageUrl ?? '')
    toast.dismiss()
  }
  const getProduct = async () => {
    const productResponse = await epiGetProduct(productId ?? '')

    if (!productResponse) {
      console.log('Unable to find product for productId', productId)
      return
    }
    const variantResponse = await epiGetCatalogChildren<VariantContentModel>(productId ?? '')
    if (!variantResponse || !variantResponse.length) {
      console.log('Unable to find variants for productId', productId)
      return
    }
    const pricesResponse = await epiGetPrices(
      variantResponse.map((v) => v.contentLink.guidValue),
      user?.authInfo?.market ?? DEFAULT_MARKET,
      user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE,
    )

    const variantModels = [] as VariantModel[]
    for (const variant of variantResponse) {
      const priceEntry = pricesResponse?.find((r) => r.entryCode === variant.code)
      variantModels.push(variantContentModelToVariantModel(variant, productResponse.code, priceEntry?.prices ?? []))
    }
    setProduct(productContentModelToProductModel(productResponse))
    setVariants(variantModels)
    if (variantModels.length) setCurrentVariant(variantModels[0])
  }

  useEffect(() => {
    if (productId) {
      getProduct()
    }
  }, [productId, user?.authInfo?.userId])

  useEffect(() => {
    if (!currentVariant) return
    const getCurrentVariantInventory = async () => {
      const inventoryResponse = await epiGetInventory(currentVariant.contentId)
      setCurrentInventories(inventoryResponse)
    }
    getCurrentVariantInventory()
    // Push the 'view_item' GTM event
    viewItemGTM(productId, currentVariant, currentPrice)
  }, [currentVariant])

  const onAddToCart = async () => {
    if (!currentVariant) {
      toast(
        <div className="flex flex-col gap-2">
          <span className="text-red-600">{localizations?.invalidVariant}</span>
        </div>,
        { position: 'top-right' },
      )
      return
    }

    const success = await addToCart(cartData?.fullCart ?? null, currentVariant, quantity)
    if (success) {
      // Push the 'add_to_cart' GTM event
      addToCartGTM(productId, currentVariant, currentPrice, quantity, cartData?.cartInfo)

      toast(
        <div className="flex flex-col gap-2">
          <span className="text-black">Added to your cart!</span>
          <SharedButton className="flex w-full flex-row gap-2" style="primary" onClick={navigateToCart}>
            <ShoppingCartIcon className="text-blue-primary ml-2 h-5 w-5" /> {localizations?.viewCartButton}
          </SharedButton>
        </div>,
        { position: 'top-right' },
      )
    }
  }

  const onQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let targetValue = +event.target.value
    const max = currentVariant?.maxQuantity ?? 1
    const min = currentVariant?.minQuantity ?? 1
    if (targetValue > max) targetValue = max
    if (targetValue < min) targetValue = min
    setQuantity(targetValue)
  }

  const currentPrice = currentVariant?.prices
    .sort((a, b) => b.minQuantity - a.minQuantity)
    ?.find((p) => quantity >= p.minQuantity)?.price

  const isVariantAvailable =
    props.model.currentMarketValid &&
    !currentVariant?.isUnavailable &&
    !currentVariant?.callForPrice &&
    !!currentInventories &&
    !!currentInventories.find((i) => i.purchaseAvailableQuantity > 0)

  const addToCartElement = isVariantAvailable ? (
    <SharedButton
      className="flex w-full flex-row gap-2"
      style="primary"
      disabled={(currentPrice ?? 0) <= 0}
      onClick={async () => await onAddToCart()}
    >
      <ShoppingCartIcon className="text-blue-primary ml-2 h-5 w-5" /> {localizations?.addToCart}
    </SharedButton>
  ) : (
    <SharedButton className="flex w-full flex-row gap-2" style="primary" disabled={true}>
      {localizations?.nonExistentProduct}
    </SharedButton>
  )

  function isVideoUrl(url: string | undefined): boolean {
    if (url === undefined || url === null) {
      return false
    }
    return /\.(mp4|mov|avi|mkv|webm)$/.test(url.toLowerCase())
  }

  const [selectedImageIndex, setSelectedImageIndex] = useState(0)

  const handleTabClick = (index: number) => {
    setSelectedImageIndex(index)
  }

  return (
    <>
      <PageHead
        productDisplayName={product?.displayName} // Pass the product's display name as a prop
      />
      <div className="container mt-5 flex flex-col py-0 font-mulish">
        <BackButton className={'mb-3'} label={'BACK TO MY SEARCH'} />
        <div className="flex flex-col">
          {RenderContentArea(props.model.topContentArea)}
          <div className="flex w-full flex-col gap-[12px] pb-[10px]">
            <h1 className="text-2xl font-bold text-grey-dark lg:text-4xl">{props.model.pageTitle}</h1>
            <span>{props.model.pageDescription}</span>
          </div>
          <div className="flex grid-cols-12 flex-col gap-7 md:grid">
            <div className="flex-auto md:col-span-5">
              <div className="flex grid grid-cols-5 flex-col gap-4">
                <div className="col-span-4 flex-auto">
                  {isVideoUrl(currentVariant?.images[selectedImageIndex]) ? null : (
                    <div className="overflow-hidden rounded-lg">
                      <img
                        className="w-full"
                        src={currentVariant?.images[selectedImageIndex]}
                        alt={`Image ${selectedImageIndex + 1}`}
                      />
                    </div>
                  )}
                </div>

                {(currentVariant?.images?.length ?? 0) > 1 ? (
                  <div className="col-span-1 flex flex-auto flex-col gap-2">
                    {currentVariant?.images.slice(0, 5).map((imageUrl, index) =>
                      isVideoUrl(imageUrl) ? null : (
                        <div
                          key={imageUrl}
                          role="button"
                          tabIndex={0}
                          className={`relative cursor-pointer overflow-hidden rounded-lg border-[2px] border-solid pb-[100%] ${index === selectedImageIndex ? 'border-grey-300' : 'border-transparent'}`}
                          onClick={() => handleTabClick(index)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleTabClick(index)
                            }
                          }}
                        >
                          <SharedImage
                            imageAlt={product?.displayName ?? 'No name provided'}
                            imageType="information"
                            url={imageUrl}
                            className="absolute h-full w-full object-cover"
                          />
                        </div>
                      ),
                    )}
                  </div>
                ) : (
                  <div className="flex flex-auto flex-col gap-2 md:col-span-1"></div>
                )}
              </div>
            </div>
            <div className="flex-1 text-grey-dark md:col-span-7">
              <div className="flex w-full flex-col gap-4 pb-[10px]">
                <div className="text-xs font-bold">{currentVariant?.variantCode}</div>
                <div>
                  <span className="text-2xl font-bold text-grey-dark lg:text-4xl">{product?.displayName}</span>
                </div>
                {!currentVariant?.callForPrice && (
                  <div>
                    <div className="text-xl font-bold text-grey-dark">
                      <Price
                        currency={user?.authInfo?.currencyCode ?? DEFAULT_CURRENCY_CODE}
                        culture={culture}
                        price={currentPrice?.toString() ?? '0'}
                      />
                    </div>

                    {currentVariant?.prices?.length! > 1 && (
                      <div className="mb-3 mt-3 flex">
                        <TierPricing rows={currentVariant?.prices.reverse() ?? []} localization={model.localizations} />
                      </div>
                    )}
                  </div>
                )}
                <SharedRtf className="!text-base" html={product?.shortDescription ?? ''} />

                <div className="!text-base">{RenderContentArea(props.model.productBlurbContentArea)}</div>

                <div className="flex flex-row items-end gap-4 border-b border-t border-solid border-grey-secondary-dark pb-7 pt-4">
                  <div className="w-[95px]">
                    <div className="pb-2 text-sm font-semibold">Quantity</div>
                    <div>
                      <input
                        className="m-0 w-full appearance-none rounded-lg border border-grey-secondary-light px-3 py-2.5 text-center shadow-sm focus:shadow-focus"
                        type="number"
                        value={quantity}
                        max={currentVariant?.maxQuantity ?? 1}
                        min={currentVariant?.minQuantity ?? 1}
                        onChange={(event) => onQuantityChange(event)}
                        disabled={!isVariantAvailable}
                      />
                    </div>
                  </div>
                  <div className="grow">{addToCartElement}</div>
                </div>

                <div className="pt-4">
                  {product?.warningMessage !== null && product?.warningMessage !== '' && (
                    <div className="mb-8 flex items-center rounded-lg border border-light-blue-primary bg-light-blue-100 px-2 py-1">
                      <InformationCircleIcon className="mr-2 h-6 w-6" />
                      <span className="text-sm font-semibold text-grey-600">{product?.warningMessage}</span>
                    </div>
                  )}

                  {product?.moreDescription !== null && product?.moreDescription !== '' && (
                    <div>
                      <SharedRtf className="pb-3 !text-base" html={product?.moreDescription ?? ''} />
                    </div>
                  )}

                  <div className="!text-base">{RenderContentArea(props.model.productBodyContentArea)}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3 mt-3">{RenderContentArea(props.model.bottomContentArea)}</div>
        </div>
      </div>
    </>
  )
}
export default withHydration(ProductDetailPage)

function productContentModelToProductModel(productContentModel: ProductContentModel) {
  return {
    contentId: productContentModel.contentLink.guidValue,
    name: productContentModel.name,
    displayName: productContentModel.displayName,
    moreDescription: productContentModel.moreDescription,
    shortDescription: productContentModel.shortDescription,
    images: productContentModel.assets,
    code: productContentModel.code,
    warningMessage: productContentModel.warningMessage,
  } as unknown as ProductModel
}

function variantContentModelToVariantModel(
  variantContentModel: VariantContentModel,
  productCode: string,
  prices: PriceContentModel[],
) {
  return {
    productCode,
    variantCode: variantContentModel.code,
    displayName: variantContentModel.name,
    contentId: variantContentModel.contentLink.guidValue,
    prices,
    images: variantContentModel.assets,
    isUnavailable: variantContentModel.isUnavailable,
    maxQuantity: variantContentModel.maxQuantity,
    minQuantity: variantContentModel.minQuantity,
    callForPrice: variantContentModel.callForPrice,
  } as VariantModel
}
